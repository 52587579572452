import styled from 'styled-components';


export const Posts = styled.div`
    max-width: 120rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 414px) {
      padding: 70px 16px 0;
    }

    &:before,
    &:after {
      display: block;
      content: '';
      clear: both;
    }
    `;

export const Subhead = styled.h5`
  font-family: 'Source', sans-serif;
  max-width: 60rem;
  padding-bottom: 5rem;
  margin: 0 auto;
  font-size: 1.8rem;
  line-height: 2.5rem;
  text-align: center;
  position: relative;
`;

export const Post = styled.div`
    width: 50%;

    z-index: 1;
    height: 57.5rem;
    box-sizing: border-box;
    position: relative;
    font-family: 'Source', sans-serif;
    display: inline-block;

    .motion {
      height: 100% !important;
    }

    p, span {
    text-decoration: none;
  }

  @media (max-width: 800px)  {
      width: 100%;
      padding: 25px 0;
    }
    @media (max-width: 1200px) and (min-width: 800px)  {
      width: 50%;
      padding: 25px;
    }
    @media (min-width: 1201px)  {
      width: 33.33333%;
      padding: 25px;
    }
`;

export const Card = styled.div`
    margin: 0 auto;
    background-color: #FFF;
    height: 100%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, .3);
    border-radius: 4px;
    margin: 0 auto;
    max-width: 45rem;
    position: relative;
    transition: all .4s ease;

    &:hover {
      transform: scale(1.04);
      box-shadow: 0 2.5rem 6rem rgba(0, 0, 0, .3);
    }

    &:active {
      transform: scale(0.98);
    }

    .Philosophy span, .Philosophy {
        background-color: #BA2B4A;
    }

    .Tech span, .Tech {
        background-color: #337995;
    }

    .Statistics span, .Statistics {
        background-color: #337995;
    }

    .Careers span, .Careers {
        background-color:#E9DA65;
    }

    .Science span, .Science {
        background-color: #00D27D;
    }

    .Business span, .Business {
        background-color: #CF804A;
    }

    .General span, .General {
        background-color: #32373B;
    }

    .Equality span, .Equality {
        background-color: #C7467E;
    }
`;

export const Category = styled.div`
    height: 50px;
    border-radius: 4px 4px 0 0;
    
    h5, h5:visited, h5:active, h5:hover{
        color: #FFF;
        text-decoration: none !important;
        font-family: Source;
        font-size: 1.8rem;
        line-height: 3rem;
        position: absolute;
        top: 1.1rem;
        left: 2rem;
        max-width: 75%;
        color: white;
    }

    p {
    position: absolute;
    right: 1.4rem;
    top: 0;
    height: 7rem;
    padding-top: 1.4rem;
    color: white;

    svg {
      font-size: 2.5rem;
    }
    }
`;

export const Content = styled.div`
    height: 300px;

    time {
        position: absolute;
        bottom: 3rem;
        padding: 1.8rem;
        max-width: 85%;
        font-size: 1.6rem;

        span {
        padding: .6rem;
        color: green; 
        text-decoration: none;
        }
    }
`;

export const Image = styled.figure`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
`;

export const Title = styled.h3`
    text-align: right;
    text-decoration: none !important;
    line-height: 3.25rem;
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-50%, -50%);
    max-width: 75%;
    color: white;
    
    span {
      @media (min-width: 400px)  {
        font-size: 2.3rem !important;
      }
      @media (max-width: 400px)  {
        font-size: 2rem !important;
      }
      
      box-decoration-break: clone;
      background-image: linear-gradient(
        to right bottom, 
        rgba(0, 0, 0, .14), 
        rgba(255, 255, 255, .26)
        );
      padding: 0.5rem 2rem;
      margin-right: 5rem;
      margin-left: 2rem;
    }
`;

export const Description = styled.div`
    position: absolute;
    top: 59%;
    width: 100%;

    p {
      @media (min-width: 400px)  {
        font-size: 1.8rem !important;
        line-height: 2.7rem;
      }
      @media (max-width: 400px)  {
        font-size: 1.7rem !important;
        line-height: 2.6rem;
      }
      max-width: 45rem;
      color: green; 
      padding: 1.8rem;
    }
`;

export const Date = styled.time`
    color: green; 
`;

export const Tags = styled.div`
    position: absolute;
    bottom: .1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.8rem;
    max-width: 85%;
    font-size: 1.6rem;
`;

export const Tag = styled.span`
    padding: .6rem 1rem;
    color: green; 
    text-decoration: none;
`;

import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const Container = ({ section, children, pushup }) => <Styled.Container section={section} className={pushup ? "pushup": ""}>{children}</Styled.Container>;

Container.propTypes = {
  section: PropTypes.bool,
  children: PropTypes.any.isRequired
};
export default Container;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import Posts from 'components/Posts';

const BlogPage: React.FC = () => {
  const { markdownRemark } = useStaticQuery(graphql`
      query {
        markdownRemark(frontmatter: { category: { eq: "blog section" } }) {
          frontmatter {
            title
            subtitle
            seotitle
            seodescription
          }
        }
      }
  `);
  
  const subtitle = markdownRemark.frontmatter.subtitle;
  const title = markdownRemark.frontmatter.title;
  const seotitle = markdownRemark.frontmatter.seotitle;
  const seodescription = markdownRemark.frontmatter.seodescription;


  return (
    <Layout>
      <Hero title={title} subtitle={subtitle}/>
      <SEO title={seotitle} description={seodescription} />
      <Posts className="pushup" />
    </Layout>
  );
};

export default BlogPage;

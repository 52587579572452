import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import * as Styled from './styles';

const Hero = (props) => {
  const { hero, herobottom } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      hero: file(relativePath: { eq: "hero-main.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      herobottom: file(relativePath: { eq: "hero-bottom.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (<>
    <Styled.Hero>
      <Img fluid={hero.childImageSharp.fluid} alt='Ruth Dillon-Mansfield (née Ng) mountain illustration' />
      <div className="header__text-box">
        <h1 className="header__desktop">
            <span className="header__top">{props.title}</span>
            <span className="header__bottom">{props.subtitle}</span>
        </h1>
        <h1 className="header__mobile">
            <span className="header__top">{props.title}</span>
            <span className="header__bottom">{props.subtitle}</span>
        </h1>
      </div>
    </Styled.Hero>
    <Styled.Anchor>
      <Styled.HeroBottom>
        <Img fluid={herobottom.childImageSharp.fluid} alt='Ruth Dillon-Mansfield (née Ng) mountain illustration reflection' />
      </Styled.HeroBottom>
    </Styled.Anchor>
  </>
);
}

Hero.defaultProps = {
};

Hero.propTypes = {
  siteTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired
};

export default Hero;
